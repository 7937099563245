.loading-overlay-spinner {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
}
